<template>
  <div id="about">
    <div class="about-div1 d-flex justify-center align-center">Our Mission</div>
    <div :class="windowWidth > 800?'text mt-5 mb-4 mx-8 pa-2 d-flex justify-center':'text pa-4 d-flex justify-center'">
      <div :style="windowWidth > 800? {'width':'70%'}:{}">
        To build long lasting  relationships with our customers by improving efficiency of distribution process 
        through innovative and cost-effective logistics solutions of consistent quality and high standards.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Mission',
  props: ['windowWidth']
}
</script>
<style scoped>
.about-div1{
  border: 1px solid #ffffff8a;
  width: inherit;
  /* background-image: url("../assets/title-bg.jpg"); */
  background-color:  #040303c4; /*#001e4fe8;*/
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: whitesmoke;
}
.text{
  font-size: 24px;
    background-color: #2b2f31d1  ;
    line-height: 1.2;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
}
</style>

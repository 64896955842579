<template>
  <div id="contact">
    <div class="about-div1 d-flex justify-center align-center">Contact</div>
    <!-- <div :class="windowWidth > 800?'text mt-5 mb-4 mx-8 pa-2':'text'">
      <div class="d-flex justify-space-around flex-wrap">
        <div>
        <h4 class="pt-4">Our Address</h4>
        <div style="font-size:20px" class="pa-4 mb-4">
        Plot No 5, Daliya Ladiya,<br />
        Datta Mandir Road,<br />
        Pushpa Park,<br />
        Mumbai 400097
        </div>
        </div>
        <div>
        <h4 class="pt-4">Mail us</h4>
        <div style="font-size:20px" class="pa-4 mb-4">
        Sales@rpgmlogistix.com
        </div>
        </div>
      </div>
        <div class="d-flex justify-space-around flex-wrap">
            <div class="pa-2 ma-2">
                Kamlesh Bachwani<br />
                Sales@rpgmlogistix.com<br />
                9930771484<br />
            </div>
            <div class="pa-2 ma-2">
                Prathamesh More<br />
                Sales@rpgmlogistix.com<br />
                9987280337<br />
            </div>
            <div class="pa-2 ma-2">
                Vivek Patil<br />
                Sales@rpgmlogistix.com <br />
                8591557833<br />
            </div>
        </div>
    </div> -->
    <v-container>
      <v-row :class="windowWidth>900?'text ma-10':'text'">
        <v-row>
          <v-row class="mt-5">
            <v-col cols="12">
            <h4>Our Address</h4>
            <div style="font-size:20px">
              Plot No 5, Daliya Ladiya,<br />
              Datta Mandir Road,<br />
              Pushpa Park,<br />
              Mumbai 400097
            </div>
            </v-col>
            <v-col cols="12">
            <h4>Mail us</h4>
            <div style="font-size:20px">
              Sales@rpgmlogistix.com
            </div>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col>
            <div class="pa-2 ma-2">
                Kamlesh Bachwani<br />
                Sales@rpgmlogistix.com<br />
                9930771484<br />
            </div>
          </v-col>
          <v-col>
            <div class="pa-2 ma-2">
                Prathamesh More<br />
                Sales@rpgmlogistix.com<br />
                9987280337<br />
            </div>
          </v-col>
          <v-col>
            <div class="pa-2 ma-2">
                Vivek Patil<br />
                Sales@rpgmlogistix.com <br />
                8591557833<br />
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'Contact',
  props: ['windowWidth']

}
</script>
<style scoped>
.about-div1{
  border: 1px solid #ffffff8a;
  width: inherit;
  /* background-image: url("../assets/title-bg.jpg"); */
  background-color: #040303c4; /*#0000006e; #001e4fe8;*/
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: whitesmoke;
}
.text{
  font-size: 24px;
    background-color: #2b2f31d1;
    line-height: 1.2;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
}
</style>

<template>
  <div id="home" class="home">
        <div class="home-div1 home-div-12-common d-flex justify-center">RPGM  Worldwide<br/> Logistix</div>
        <div class="home-div2 home-div-12-common"></div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
}
</script>
<style scoped>
    .home {
        width:100%;
        height:110vh;
        background-image: url("../assets/BackGround3.jpg");
        position: relative;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .home-div-12-common{
        background-color: #0000006e;
        position: absolute;
        border: 3px solid #ffffff8a;
    }
    .home-div1{
        width: 82%;
        height: 50%;
        right: 0;
        top: 30%; 
        color: whitesmoke;
        font-size:1.5em;
    }
    .home-div2{
        width: 30%;
        height: 40%;
    }
</style>

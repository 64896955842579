<template>
  <div id="services">
    <div class="services-div d-flex justify-center align-center">Services</div>
    <v-container>
      <v-row>
        <div :class="windowWidth > 800?'text mt-5 mb-4 mx-12 pa-2':'text ma-2 pa-2'">
        We offer Door to Door services courier as well as commercial through all reputed integrators(Fedex Dhl TNT Aramax UPS etc)
        </div>
        <v-col cols="12" md="6" class="text d-flex align-center">
    <!-- <div class="text services-div4 d-flex align-center pa-2 ma-4"> -->
      <div class="pa-4" >
       <h3>Freight Forwarding – Sea</h3><br/> 
        With established relationships with global carriers we ensure 
        that our customers gets the best possible price to move goods 
        from one location to another. Our team ensures correct filing of 
        export documentation, all arrangements with carriers, packing, 
        crating and storage of cargo.
      </div>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-center">
        <div :style="windowWidth>800?{'height':'275px'}:{'height':'210px'}"><img class="image" src="../assets/cargo-container-ship.jpg" /></div>
      </v-col>
    <!-- </div> -->
      </v-row>
      <v-row class="flex-wrap-reverse">
    <!-- <div class="text services-div4 d-flex align-center pa-2 ma-4"> -->
      <v-col cols="12" md="6" class="d-flex justify-center">
        <div :style="windowWidth>800?{'height':'275px'}:{'height':'210px'}"><img class="image" src="../assets/aeroplane.jpg" /></div>
      </v-col>
      <v-col cols="12" md="6" class="text d-flex align-center">
      <div class="pa-4">
       <h3> Freight Forwarding – Air</h3><br/>
        From Perishable to General cargo, our team is specialized in 
        handling, delivering and clearing all types of cargo as per your 
        requirements such as Door to door service, Fair & Exhibition, Third country shipment, 
        Temperature shipment, Project cargo, Live Animals. With effective time management and information 
        sharing we ensure your cargo reaches it destination on time.
      </div>
      </v-col>
    <!-- </div> -->
      </v-row>
      <v-row>
    <!-- <div class="text services-div4 d-flex align-center pa-2 ma-4"> -->
      <v-col cols="12" md="6" class="text d-flex align-center">
      <div class="pa-4">
       <h3>Domestic Services</h3><br/> 
        We provide efficient, professional, and competitive transport 
        service for all your road transportation needs. Our vast 
        experience in the domain ensures our customers a reliable door-to-door transport solution for their cargo.
      </div>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-center">
        <div :style="windowWidth>800?{'height':'275px'}:{'height':'210px'}"><img class="image" src="../assets/truck-trailer.jpg" /></div>
      </v-col>
    <!-- </div> -->
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'Services',
  props: ['windowWidth']
}
</script>
<style scoped>
.services-div{
  border: 1px solid #ffffff8a;
  width: inherit;
  /* background-image: url("../assets/title-bg.jpg"); */
  background-color: #040303c4; /* #0000006e; #001e4fe8;*/
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: whitesmoke;
}
.text{
  font-size: 20px;
    background-color: #2b2f31d1;
    line-height: 1.2;
    /* border-bottom-left-radius: 8px;
    border-top-right-radius: 8px; */
}
.services-div-12-common{
        background-color: #0000006e;
        position: absolute;
        border: 3px solid #ffffff8a;
    }
    .services-div1{
        width: 82%;
        height: 73%;
        right: 0;
        top:20%;
        color: whitesmoke;
        font-size:1.5em;
        position: absolute;
    }
    .services-div2{
        width: 40%;
        height: 30%;
    }
    .services-div3{
        width: 40%;
        height: 30%;
        bottom:0
    }
 .services-div4{
   height: 300px;
 }
 .image{
   height: -webkit-fill-available;
 }
 
</style>

<template>
  <div id="about">
    <div class="about-div1 d-flex justify-center align-center">About us</div>
    <div :class="windowWidth > 800?'text mt-5 mb-4 mx-12 pa-2':'text ma-2 pa-2'">
      <ul>
      <li>RPGM Worldwide Logistix is global shipping service provider based in Mumbai, India.<br /><br/></li>
    <!-- </div>
    <div :class="windowWidth > 800?'text mt-5 mb-4 mx-12 pa-2':'text ma-2 pa-2'"> -->
      <li>We are servicing ports around the world. Our motive is to provide clients with best quality services at affordable rates from Freight Forwarding, Containers Booking to Transportation get the best rates in market and along with professional 
services.<br /><br/></li>
    <!-- </div>
    <div :class="windowWidth > 800?'text mt-5 mb-4 mx-12 pa-2':'text ma-2 pa-2'"> -->
      <li>The team is lead by highly experienced enthusiastic and professional leaders ensuring hassle free and timely  operations.<br /><br/></li>
    <!-- </div>
    <div :class="windowWidth > 800?'text mt-5 mb-4 mx-12 pa-2':'text ma-2 pa-2'"> -->
      <li>Our guiding philosophy is to continuously set the bar higher, set new benchmarks in advancing quality and professional services to all our clients.<br/><br/></li>
    <!-- </div>
    <div :class="windowWidth > 800?'text mt-5 mb-4 mx-12 pa-2':'text ma-2 pa-2'"> -->
      <li>Our Team is manged by senior experienced personnel who are equipped to handle all kinds of issues pertaining to a vessels port call.<br /><br/></li>
    <!-- </div>
    <div :class="windowWidth > 800?'text mt-5 mb-4 mx-12 pa-2':'text ma-2 pa-2'"> -->
      <li>We provide Continuous in-house training with-in the organization gives our operations executives useful knowledge.<br /><br/></li>
    <!-- </div>
    <div :class="windowWidth > 800?'text mt-5 mb-4 mx-8 pa-2':'text ma-2 pa-2'"> -->
      <li>Excellent client service , understanding of the principal's requirements and prompt responses are our core Competencies.<br /><br/></li>
    </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About',
  props: ['windowWidth']
}
</script>
<style scoped>
.about-div1{
  border: 1px solid #ffffff8a;
  width: inherit;
  /* background-image: url("../assets/title-bg.jpg"); */
  background-color:#040303c4; /* #001e4fe8; */
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: whitesmoke;
}
.text{
  font-size: 20px;
    background-color: #2b2f31d1; /*#022e48e6;*/
    line-height: 1.2;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}
</style>

<template>
<v-container>
    <Home />
    <div class="background">
    <About :windowWidth="windowWidth" />
    <Services :windowWidth="windowWidth" />
    <Mission :windowWidth="windowWidth" />
    <Contact :windowWidth="windowWidth" />
    </div>
</v-container>
</template>
<script>
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Services from '@/views/Services.vue'
import Mission from '@/views/Mission.vue'
import Contact from '@/views/Contact.vue'

export default {
    name: 'MainSite',
    components: {
        Home,
        About,
        Services,
        Mission,
        Contact
    },
    props: ['windowWidth']
}
</script>
<style scoped>
.background {
  width:100%;
  height:fit-content;
  background-image: url("../assets/services-bg.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
</style>
